module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"rebeccapurple"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.realcedar.com/graphql","verbose":true,"schema":{"perPage":50,"queryDepth":6,"typePrefix":"Wp","timeout":120000,"requestConcurrency":5,"previewRequestConcurrency":2,"circularQueryLimit":5},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":true,"placeholderType":"dominantColor"},"develop":{"nodeUpdateInterval":5000,"hardCacheMediaFiles":true,"hardCacheData":false},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"panicOnError":false,"copyQueryOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"Tag":{"exclude":true},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"MediaItem":{"localFile":{"maxFileSizeBytes":99999999,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NQ3C9L","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-40595314-2","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"535317976629334"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
