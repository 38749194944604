import axios from "axios"

const getIp = async () => {
  try {
    let response = await axios.get("https://api.ipify.org?format=json")

    if (response?.data?.ip) {
      return response.data.ip
    } else {
      return false
    }
  } catch (err) {
    console.log(err)
    return false
  }
}

export default getIp
